import { useHistory, useParams } from "react-router-dom";
import { IPlayer, IScoreCard } from "./AceRaceApi";
import { useEffect } from "react";
import { ThrowScoreInput } from "./ThrowScoreInput";

interface IHolePagerProps {
    allPlayers?: IPlayer[];
    scoreCard?: IScoreCard;
}

export function HolePager(props: IHolePagerProps) {
    let history = useHistory();
    let { hole } = useParams<{ hole: string }>();

    useEffect(() => {
        if (!props.allPlayers || !props.scoreCard) {
            history.replace("/ace-race")
        }

    })
    if (!props.allPlayers || !props.scoreCard) {
        return null;
    }

    return (
        <div>
            <h1>Hole {hole}</h1>

            {props.scoreCard.playerScores.map((score) => {
                const displayName = props.allPlayers!.find((player) => { return player.username === score.username; })!.displayName;
                return (

                    <div>
                        <h2>{displayName}</h2>
                        <span>Throw 1</span><ThrowScoreInput />
                        <br />
                        <span>Throw 2</span><ThrowScoreInput enableBirdie={true} />
                    </div>
                );
            })}





            <button onClick={() => { history.goBack(); }}>PREV</button>
            <button onClick={() => {
                const n = parseInt(hole);
                const n2 = n + 1;
                history.push("/ace-race/score-card/" + n2);

            }}>NEXT</button>
        </div >
    );
}