import { makeAutoObservable } from "mobx";

import { Player } from "src/puttsnpints/models/Player";
import { PuttsNPintsModel } from "src/puttsnpints/models/PuttsNPintsModel";

export class AppModel {
  readonly puttsnpints: PuttsNPintsModel;

  constructor() {
    this.puttsnpints = new PuttsNPintsModel();

    makeAutoObservable(this);
  }

  async initialize() {
    await this.puttsnpints.initialize();
  }
}
