import React from "react";
import "./App.css";
import { observer } from "mobx-react-lite";
import { Switch, Route, Link } from "react-router-dom";

import { Home } from "./Home";
import { MapPage } from "./MapPage";
import { NotFound } from "./NotFound";
import { StandardGame } from "src/standardgame/StandardGame";
import { PuttsNPints } from "src/puttsnpints/views/PuttsNPints";

import { AppModel } from "src/app/models/AppModel";
import { AceRace } from "src/acerace/AceRace";

interface IAppProps {
  model: AppModel;
}

export const App = observer((props: IAppProps) => {
  const model = props.model;
  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/ace-race">
          <AceRace />
        </Route>
        <Route path="/map">
          <MapPage />
        </Route>
        <Route path="/standardgame">
          <StandardGame />
        </Route>
        <Route path="/puttsnpints">
          <PuttsNPints model={model.puttsnpints} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
});
