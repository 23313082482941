import { makeAutoObservable } from "mobx";
import { AceRaceApi, IPlayer, IScoreCard } from "./AceRaceApi";
import { IUserAuth } from "./IUserAuth";
import { LoginType } from "./LoginType";


const api = new AceRaceApi();

export class AceRaceVM {

    loginMode: LoginType = "player";
    initialized: boolean = false;
    userAuth?: IUserAuth;

    busy: boolean = false;

    eventTitle: string = "";
    eventLocation: string = "";
    eventDate: string = "";
    eventUrl: string = "";

    playerUsername: string = "";
    playerPassword: string = "";
    playerDisplayName: string = "";

    allPlayers: IPlayer[] = [];
    groupTitle: string = "";
    groupUsernames: string[] = [];
    occupiedPlayers: string[] = [];

    scoreCard?: IScoreCard;



    constructor() {
        makeAutoObservable(this);
    }

    async initialize() {
        console.log("AceRaceVM.initialize()");

        const userAuthJSON = localStorage.getItem("userAuthJSON");
        console.log("userAuthJSON", userAuthJSON)
        let userAuth: IUserAuth | undefined;
        if (userAuthJSON) userAuth = JSON.parse(userAuthJSON);
        if ((userAuth && userAuth.type && userAuth.username && userAuth.token)) {
            if (userAuth.type === 'admin') {
                const result = await api.adminVerify(userAuth.username, userAuth.token);
                if (!result) userAuth = undefined;
            } else {
                const result = await api.playerVerify(userAuth.username, userAuth.token);
                if (!result) userAuth = undefined;
            }
        }

        this.initialized = true;
        this.userAuth = userAuth;

    }

    async login(userAuth: IUserAuth) {
        this.userAuth = userAuth;
        localStorage.setItem("userAuthJSON", JSON.stringify(userAuth));
    }

    async logout() {
        this.userAuth = undefined;
        localStorage.removeItem("userAuthJSON");
    }

}