// import "@arcgis/core/assets/esri/themes/light/main.css";
import "./EsriViewHost.css";

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
// import { MapView, SceneView } from 'cd-eft/utils/esriTypes';

interface IEsriViewHostProps {
  // esriView: MapView | SceneView | null | undefined;
  esriView: any;
}

function cleanup(this: any) {
  // const bindings = (this as unknown) as any;
  // console.warn('effect', this);
  if (this.lastEsriView.container === this.divREF.current.firstChild) {
    // console.warn('GOT HERE!');
    this.lastEsriView.container = undefined as any;
  }
}

export const EsriViewHost = observer((props: IEsriViewHostProps) => {
  // console.log('EsriViewHost.render()', props.esriView);
  const divREF = useRef<any>();
  const [lastEsriView, setLastEsriView] = useState<any>();

  useEffect(() => {
    let doAfterEffect = false;

    // console.log('EsriViewHost.useEffect()', lastEsriView);
    // check to see if esriView has changed since last render
    // console.warn('props', props);
    if (lastEsriView !== props.esriView) {
      if (lastEsriView) {
        // NOTE: only clear the container of previous value if it is still referencing this divREF
        // this improves switching performance, and also doesn't disrupt an esriView that has
        // already been relocated to another container

        // the business condition
        // console.warn('lastEsriView.container', lastEsriView.container);
        // console.warn('divREF.current', divREF.current);
        // if (lastEsriView.container === divREF.current) {
        //   console.warn('GOT HERE!');
        //   lastEsriView.container = undefined as any;
        // }

        // setTimeout(effect.bind({ lastEsriView: lastEsriView, divREF: divREF }), 0);
        //effect.bind({ lastEsriView: lastEsriView, divREF: divREF })();
        doAfterEffect = true;

        // setTimeout(() => {
        // // SPECIAL UNIT-TEST NOTE: used a special mock "agent" switch to access an otherwise inaccessible branch of code
        // const agentHost: any = props.esriView;
        // if (agentHost && agentHost.____unit_test_agent && agentHost.____unit_test_agent.switchA) {
        //   //export the value for testing
        //   agentHost.____unit_test_agent.beforeA = lastEsriView.container;

        //   //force the condition to be true
        //   lastEsriView.container = divREF.current.firstChild;
        // }

        //   // //NOTE: the setTimeout(0) improves visual performance
        //   // if (lastEsriView.container === divREF.current.firstChild) {
        //   //   // console.warn('GOT HERE!');
        //   //   lastEsriView.container = undefined as any;
        //   // }

        //   // // SPECIAL UNIT-TEST NOTE: used a special mock "agent" switch to access an otherwise inaccessible branch of code
        //   // if (agentHost && agentHost.____unit_test_agent && agentHost.____unit_test_agent.switchA) {
        //   //   //export the value for testing
        //   //   agentHost.____unit_test_agent.afterA = lastEsriView.container;
        //   // }
        // }, 0);
        // if (props.esriView && lastEsriView.container === divREF.current.firstChild) {
        //   console.warn('GOT HERE!');
        //   lastEsriView.container = undefined as any;
        // }
        // if (!props.esriView && lastEsriView.container === divREF.current.firstChild) {
        //   console.warn('GOT HERE!');
        //   lastEsriView.container = undefined as any;
        // }
      }
      setLastEsriView(props.esriView);
    }

    if (props.esriView) {
      if (!props.esriView.container) {
        props.esriView.container = document.createElement("div");
      }
      const container = props.esriView.container as any;
      container.dataset.testid = "eft-esriviewcontainer";
      container.classList.add("eft-esriviewcontainer");

      while (divREF.current.firstChild) {
        divREF.current.removeChild(divREF.current.firstChild);
      }
      divREF.current.appendChild(props.esriView.container);
    }
    // if (props.esriView) {
    //   props.esriView.container = divREF.current;
    // }

    if (doAfterEffect) {
      return () => {
        // console.log('afterEffect');

        // SPECIAL UNIT-TEST NOTE: used a special mock "agent" switch to access an otherwise inaccessible branch of code
        const agentHost: any = props.esriView;
        if (
          agentHost &&
          agentHost.____unit_test_agent &&
          agentHost.____unit_test_agent.switchA
        ) {
          //export the value for testing
          agentHost.____unit_test_agent.beforeA = lastEsriView!.container;

          //force the condition to be true
          lastEsriView!.container = divREF.current.firstChild;
        }

        // run the business code
        cleanup.bind({ lastEsriView: lastEsriView, divREF: divREF })();

        // SPECIAL UNIT-TEST NOTE: used a special mock "agent" switch to access an otherwise inaccessible branch of code
        if (
          agentHost &&
          agentHost.____unit_test_agent &&
          agentHost.____unit_test_agent.switchA
        ) {
          //export the value for testing
          agentHost.____unit_test_agent.afterA = lastEsriView!.container;
        }
      };
    }
  }); // run useEffect on every render

  return (
    <div
      className="eft-esriviewhost"
      data-testid="eft-esriviewhost"
      ref={divREF}
    >
      <div></div>
    </div>
    // <div className="eft-esriviewhost" data-testid="eft-esriviewhost">
    //   <div className="eft-esriviewcontainer" ref={divREF} data-testid="eft-esriviewcontainer" />
    // </div>
  );
});
export default EsriViewHost;
