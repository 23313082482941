import React, { Fragment, useRef } from "react";
import { observer } from "mobx-react-lite";
import { NewGameView } from "./NewGameView";
import { PuttsNPintsModel } from "../models/PuttsNPintsModel";
import { Game } from "../models/Game";
import { Button } from "react-bootstrap";

interface IPuttsNPintsProps {
  model: PuttsNPintsModel;
}

export const PuttsNPints = observer((props: IPuttsNPintsProps) => {
  const model = props.model;
  return (
    <Fragment>
      <div>PuttsNPints</div>
      <Button
        onClick={async () => {
          const players = await model.listPlayers();
          console.log("players", players);
        }}
      >
        LIST PLAYERS
      </Button>

      <Button
        onClick={async () => {
          await model.addPlayer();
        }}
      >
        ADD PLAYER
      </Button>

      <Button
        onClick={async () => {
          await model.dropDatabase();
        }}
      >
        DROP DATABASE
      </Button>
    </Fragment>
  );
  // return <NewGameView onSubmit={(game: Game) => {}} />;
  // // const modelREF = useRef<PuttsNPintsModel>();
  // // if (!modelREF.current) modelREF.current = new PuttsNPintsModel();
  // // const model = modelREF.current;
  // const model = props.model;
  // const vmREF = useRef<PuttsNPintsVM>();
  // if (!vmREF.current) vmREF.current = new PuttsNPintsVM();
  // const vm = vmREF.current;
  // const history = useHistory();
  // return (
  //   <Fragment>
  //     <p>putts n pints</p>
  //     <div>
  //       <div>Registered Players</div>
  //       <ul>
  //         {model.players.map((player: Player) => {
  //           return <li>{player.name}</li>;
  //         })}
  //       </ul>
  //       <form
  //         onSubmit={(e) => {
  //           e.preventDefault();
  //         }}
  //       >
  //         <input
  //           type="text"
  //           value={vm.playerNameText}
  //           onChange={(e) => {
  //             vm.playerNameText = e.target.value;
  //           }}
  //         ></input>
  //         <button
  //           onClick={() => {
  //             const playerName = vm.playerNameText.trim();
  //             if (playerName !== "") {
  //               const player = new Player();
  //               player.name = playerName;
  //               model.players.push(player);
  //               vm.playerNameText = "";
  //             }
  //           }}
  //         >
  //           Register
  //         </button>
  //       </form>
  //     </div>
  //     <p>
  //       <button
  //         onClick={() => {
  //           history.goBack();
  //         }}
  //       >
  //         back
  //       </button>
  //       <button
  //         onClick={() => {
  //           history.push("/");
  //         }}
  //       >
  //         home
  //       </button>
  //     </p>
  //   </Fragment>
  // );
});
