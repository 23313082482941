import React, { Fragment, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import { AceRaceApi } from './AceRaceApi'
import { makeAutoObservable } from "mobx";

const api = new AceRaceApi();

class PlayerLoginVM {

  public busy: boolean = false;
  public username: string = "";
  public password: string = "";

  constructor() {
    this.reset();
    makeAutoObservable(this);
  }

  reset() {
    this.busy = false;
    this.username = "";
    this.password = "";
  }
}

interface IPlayerLoginProps {
  onLoginSuccess?(username: string, token: string): void;
  onLoginFailure?(): void;
  onCancel?(): void;
}


export const PlayerLogin = observer((props: IPlayerLoginProps) => {
  const vmREF = useRef<PlayerLoginVM>();
  if (!vmREF.current) vmREF.current = new PlayerLoginVM();
  const vm = vmREF.current;

  return <div>
    <h1>Player Login</h1>
    <form onSubmit={(e) => { e.preventDefault(); }}>
      <fieldset disabled={vm.busy}>
        <label>
          Username
          <br />
          <input
            id="username"
            type="text"
            value={vm.username}
            onChange={(e) => { vm.username = e.target.value; }}
          />
        </label>
        <br />
        <br />
        <label>
          Password
          <br />
          <input
            id="password"
            type="password"
            value={vm.password}
            onChange={(e) => { vm.password = e.target.value; }}
          />
        </label>
        <br /><br />
        <input type='submit'

          onClick={
            async (e) => {
              vm.busy = true;
              try {
                const username = vm.username.toLocaleLowerCase();
                const password = vm.password;
                const result = await api.playerLogin(username, password);
                vm.reset();
                if (props.onLoginSuccess) props.onLoginSuccess(username, result.token!);
              } catch {
                vm.reset();
                if (props.onLoginFailure) props.onLoginFailure();
              } finally {
                vm.reset();
              }
            }
          }
        />

        <input type='button' value="Cancel"
          onClick={
            async (e) => {
              vm.reset();
              if (props.onCancel) props.onCancel();
            }
          }
        />
      </fieldset>
    </form>

  </div>;
});
