import { makeAutoObservable } from "mobx";
import { database, PlayerRow } from "src/puttsnpints/models/Database";
import { Player } from "./Player";
import { v4 as uuidv4 } from "uuid";

export class PuttsNPintsModel {
  // players: Player[] = [];
  // gameTemplates: GameTemplate[] = [];

  //   activeGames: Game[] = [];
  //   history: Game[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async initialize() {}
  async load() {}
  async save() {}

  async addPlayer() {
    console.log("PuttsNPintsModel.addPlayer()");
    const uuid = uuidv4();
    const player = new PlayerRow();
    player.uuid = uuid;
    console.log("player", player);

    await database.tables.players.setItem(uuid, player);
  }

  async listPlayers() {
    console.log("PuttsNPintsModel.listPlayers()");
    const keys = await database.tables.players.keys();
    const players = [];
    for (const key of keys) {
      // console.log("key", key);
      const row = await database.tables.players.getItem(key);
      players.push(row);
    }
    return players;
  }

  async dropDatabase() {
    console.log("PuttsNPintsModel.dropDatabase()");
    await database.dropInstance();
  }

  //   load() {}
  //   save() {}
  //   registerPlayer(player: Player) {}
  //   unregisterPlayer(player: Player) {}
}

// type miss = false;
// type hit = true;
// type ThrowResult = "in-the-basket" | "chains" | "metal" | "miss";

// export class GameTemplate {
//   name: string = "Default";
//   numRounds: number = 5;
//   stations: number[] = [12, 18, 24, 30, 36];
//   numThrowsPerStation: number = 3;
//   allInBonus: number = 1;
//   allOutPenalty: number = -1;
// }

// export class StationTemplate {
//   distance: string | undefined;
// }

// export class RoundTemplate {}

// export class Game {
//   name: string | undefined;
//   players: Player[] = [];
//   stations = [];
//   rounds = [];
//   addPlayer(player: Player) {}
//   addStation(player: Station) {}
//   start() {}
// }

// export class Player {
//   name: string | undefined;
// }

// export class Station {
//   name: string | undefined;
//   distance: string | undefined;
// }

// export class Round {
//   name: string | undefined;
// }

// export class ScoreCard {
//   name: string | undefined;
// }

// export class Ledger {
//   name: string | undefined;
// }
// export class LedgerEntry {
//   name: string | undefined;
// }
