import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { loadModules } from "esri-loader";
import EsriViewHost from "./EsriViewHost";

// const map: __esri.Map = new __esri.Map();

interface IMapPageProps {}

export const MapPage = observer((props: IMapPageProps) => {
  console.log("MapPage.render();");
  const [view, setView] = useState<any>();
  console.log("view", view);

  if (!view) {
    loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/widgets/Home",
      "esri/widgets/Locate",
      "esri/widgets/Track",
    ]).then(([esriMap, esriMapView, Home, Locate, Track]) => {
      const map = new esriMap({
        basemap: "hybrid",
      });
      const mapView = new esriMapView({
        // container: "viewDiv", // Reference to the DOM node that will contain the view
        map: map, // References the map object created in step 3
      });

      let homeWidget = new Home({
        view: mapView,
      });
      mapView.ui.add(homeWidget, "top-left");

      let locateWidget = new Locate({
        view: mapView,
      });
      mapView.ui.add(locateWidget, "top-left");

      let trackWidget = new Track({
        view: mapView,
      });
      mapView.ui.add(trackWidget, "top-left");

      setView(mapView);
    });
  }

  if (!view) return null;

  return <EsriViewHost esriView={view}></EsriViewHost>;
});
