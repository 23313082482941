import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import * as metaTags from "../utils/metaTags";

import { Button } from "react-bootstrap";

interface IHomeProps {}

export const Home = observer((props: IHomeProps) => {
  const history = useHistory();
  return (
    <Fragment>
      <p>puttstuff [0.0.0.{metaTags.CODEBUILD_BUILD_NUMBER}]</p>
      <p>
        <Button
          onClick={() => {
            history.push("/ace-race");
          }}
        >
          ace race
        </Button>
      </p>
      {/* <p>
        <Button
          onClick={() => {
            history.push("/puttsnpints");
          }}
        >
          putts n pints
        </Button>
      </p> */}

      {/* <p>
        <Button
          onClick={() => {
            history.push("/map");
          }}
        >
          map
        </Button>
      </p> */}

      <p>
        <Button
          onClick={() => {
            // https://stackoverflow.com/questions/12816542/ios6-how-to-clear-cache-of-homescreen-standalone-web-app
            // top?.frames.location.reload();
            window.top?.frames.location.reload();
          }}
        >
          reload
        </Button>
      </p>
    </Fragment>
  );
});
