import axios from "axios";


export class AceRaceApi {

    private readonly baseUrl: string = "https://zo4hr3g7egpfbviirshm3lkute0cying.lambda-url.us-east-2.on.aws/";

    public async adminLogin(username: string, password: string): Promise<ILoginResult> {
        const endpointUrl = `${this.baseUrl}?op=admin-login&u=${username}&p=${password}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async adminVerify(username: string, token: string): Promise<boolean> {
        const endpointUrl = `${this.baseUrl}?op=admin-verify&u=${username}&t=${token}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async playerLogin(username: string, password: string): Promise<ILoginResult> {
        const endpointUrl = `${this.baseUrl}?op=player-login&u=${username}&p=${password}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async playerVerify(username: string, token: string): Promise<boolean> {
        const endpointUrl = `${this.baseUrl}?op=player-verify&u=${username}&t=${token}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }


    public async listEvents(): Promise<IEvent[]> {
        const endpointUrl = `${this.baseUrl}?op=list-events`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async listPlayers(u: string, t: string): Promise<IPlayer[]> {
        const endpointUrl = `${this.baseUrl}?op=list-players&u=${u}&t=${t}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async listPlayerGroups(u: string, t: string): Promise<IPlayerGroup[]> {
        const endpointUrl = `${this.baseUrl}?op=list-player-groups&u=${u}&t=${t}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async getEvent(id: string): Promise<IEvent> {
        const endpointUrl = `${this.baseUrl}?op=get-event&id=${id}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async createEvent(title: string, date: string, location: string, url: string, u: string, t: string) {
        title = encodeURIComponent(title);
        date = encodeURIComponent(date);
        location = encodeURIComponent(location);
        url = encodeURIComponent(url);
        u = encodeURIComponent(u);
        t = encodeURIComponent(t);
        const endpointUrl = `${this.baseUrl}?op=create-event&u=${u}&t=${t}&title=${title}&date=${date}&location=${location}&url=${url}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async createPlayerLogin(username: string, password: string, displayName: string, u: string, t: string) {
        username = encodeURIComponent(username);
        password = encodeURIComponent(password);
        displayName = encodeURIComponent(displayName);
        u = encodeURIComponent(u);
        t = encodeURIComponent(t);
        const endpointUrl = `${this.baseUrl}?op=create-player-login&u=${u}&t=${t}&username=${username}&password=${password}&displayName=${displayName}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async createPlayerGroup(groupTitle: string, groupUsernames: string[], u: string, t: string) {
        groupTitle = encodeURIComponent(groupTitle);
        const p_groupUsernames = encodeURIComponent(JSON.stringify(groupUsernames));
        u = encodeURIComponent(u);
        t = encodeURIComponent(t);
        const endpointUrl = `${this.baseUrl}?op=create-player-group&u=${u}&t=${t}&groupTitle=${groupTitle}&groupUsernames=${p_groupUsernames}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

    public async getScoreCard(u: string, t: string) {
        u = encodeURIComponent(u);
        t = encodeURIComponent(t);
        const endpointUrl = `${this.baseUrl}?op=get-score-card&u=${u}&t=${t}`;
        const result = await axios.get(endpointUrl);
        return result.data;
    }

}



export interface IEvent {
    id?: string;
    title?: string;
    url?: string;
}

export interface IPlayer {
    username?: string;
    displayName?: string;
}

interface ILoginResult {
    token?: string;
}

export interface IPlayerGroup {
    groupTitle?: string;
    groupUsernames?: string[];
}

export interface IScoreCard {
    owner: string;
    // thru: number;
    final: boolean;
    playerScores: IPlayerScoreList[]
}

export interface IPlayerScoreList {
    username: string;
    // displayName: string;
    scores: HoleScore[];
}

// 1 | 4 | 7 | 10
export type HoleScore = "none" | "birdie" | "metal" | "chains" | "ace" | null;

// none = 0
// birdie = 1
// metal = 4
// chains = 7
// ace = 10

