import { makeAutoObservable } from "mobx";
import localforage from "localforage";

interface ITables {
  readonly players: LocalForage;
}

class Database {
  readonly name: string;
  readonly tables: ITables;
  // readonly tblPlayer: LocalForage;

  constructor() {
    this.name = "puttsnpints-v1";

    const players = localforage.createInstance({
      name: this.name,
      storeName: "players",
    });
    console.log("players", players);

    this.tables = { players: players };

    makeAutoObservable(this);
  }

  async dropInstance() {
    await localforage.dropInstance({ name: this.name });
    await localforage.dropInstance();
  }
}

export const database = new Database();

export class PlayerRow {
  uuid?: string;
  name?: string;
}

// interface IRow {}
// interface IPlayerRow extends IRow {}
// interface ITable<T extends IRow> {
//   getRow(): T;
// }
// interface IPlayerTable extends ITable<IPlayerRow> {}

// const t: IPlayerTable = {};
// t.getRow();
