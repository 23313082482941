import React, { Fragment, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route, Link, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { AceRaceApi, IPlayer, IScoreCard } from './AceRaceApi'
import { PlayerLogin } from "./PlayerLogin";
import { AdminLogin } from "./AdminLogin";
import { IUserAuth } from "./IUserAuth";
import { AceRaceVM } from "./AceRaceVM";
import { HolePager } from "./HolePager";

const api = new AceRaceApi();

export const AceRace = observer(() => {
  const vmREF = useRef<AceRaceVM>();
  if (!vmREF.current) vmREF.current = new AceRaceVM();
  const vm = vmREF.current;

  let history = useHistory();
  let location = useLocation();
  let { hole } = useParams<any>();
  console.log("hole", hole);
  let routeMatch = useRouteMatch();

  useEffect(() => {
    vm.initialize();
  }, [])

  if (!vm.initialized) {
    return null;
  }

  if (!vm.userAuth) {
    return (
      <div onDoubleClick={(e) => {
        if ((e.target as any).tagName === "H1") {
          if (vm.loginMode === 'admin') {
            vm.loginMode = 'player';
          } else {
            vm.loginMode = 'admin';
          }
        }
      }}>
        {(vm.loginMode === 'admin') &&
          <AdminLogin
            onLoginSuccess={async (username: string, token: string) => {
              const userAuth: IUserAuth = {
                type: "admin",
                username: username,
                token: token
              };
              await vm.login(userAuth);
            }}
            onCancel={async () => {
              history.goBack();
            }}

          />
        }

        {(vm.loginMode === 'player') &&
          <PlayerLogin
            onLoginSuccess={async (username: string, token: string) => {
              const userAuth: IUserAuth = {
                type: "player",
                username: username,
                token: token
              };
              await vm.login(userAuth);
            }}
            onCancel={async () => {
              history.goBack();
            }}
          />
        }
      </div>
    );
  }

  return <div>
    <Switch>
      <Route path="/ace-race/create-event">
        <h1>CREATE EVENT</h1>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <fieldset disabled={vm.busy}>
            <label>
              <span>Title</span>
              <br />
              <input type="text" value={vm.eventTitle} onChange={(e) => { vm.eventTitle = e.target.value }} />
            </label>
            <br />
            <label>
              <span>Date</span>
              <br />
              <input type="date" value={vm.eventDate} onChange={(e) => { vm.eventDate = e.target.value }} />
            </label>
            <br />
            <label>
              <span>Location</span>
              <br />
              <input type="text" value={vm.eventLocation} onChange={(e) => { vm.eventLocation = e.target.value }} />
            </label>
            <br />
            <label>
              <span>Url</span>
              <br />
              <input type='url' value={vm.eventUrl} onChange={(e) => { vm.eventUrl = e.target.value }} />
            </label>
            <br />
            <br />
            <input type='submit' value="OK"
              onClick={async () => {
                vm.busy = true;
                try {
                  await api.createEvent(vm.eventTitle, vm.eventDate, vm.eventLocation, vm.eventUrl, vm.userAuth!.username, vm.userAuth!.token);
                  history.goBack();
                } finally {
                  vm.busy = false;
                }
              }}
            />
            <input type='button' value='CANCEL'
              onClick={async () => {
                history.goBack();
              }}
            />

          </fieldset>
        </form>
      </Route>
      <Route path="/ace-race/create-player-login">
        <h1>CREATE PLAYER LOGIN</h1>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <fieldset disabled={vm.busy}>
            <label>
              <span>Username</span>
              <br />
              <input type="text" value={vm.playerUsername} onChange={(e) => { vm.playerUsername = e.target.value }} />
            </label>
            <br />
            <label>
              <span>Password</span>
              <br />
              <input type="text" value={vm.playerPassword} onChange={(e) => { vm.playerPassword = e.target.value }} />
            </label>
            <br />
            <label>
              <span>Display Name</span>
              <br />
              <input type="text" value={vm.playerDisplayName} onChange={(e) => { vm.playerDisplayName = e.target.value }} />
            </label>
            <br />
            <br />
            <input type='submit' value="OK"
              onClick={async () => {
                vm.busy = true;
                try {
                  await api.createPlayerLogin(vm.playerUsername, vm.playerPassword, vm.playerDisplayName, vm.userAuth!.username, vm.userAuth!.token);
                  vm.playerUsername = "";
                  vm.playerPassword = "";
                  vm.playerDisplayName = "";
                  // history.goBack();
                } finally {
                  vm.busy = false;
                }
              }}
            />
            <input type='button' value='CANCEL'
              onClick={async () => {
                history.goBack();
              }}
            />

          </fieldset>
        </form>
      </Route>
      <Route path="/ace-race/create-player-group">
        <h1>CREATE PLAYER GROUP</h1>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <fieldset disabled={vm.busy}>
            <label>
              <span>Group Title</span>
              <br />
              <input type="text" value={vm.groupTitle} onChange={(e) => { vm.groupTitle = e.target.value }} />
            </label>
            <br />
            <div className="check-list">
              {vm.allPlayers.map((player, index) => {
                const occupied = (vm.occupiedPlayers.indexOf(player.username!) >= 0)
                return (<div key={index} hidden={occupied}>
                  <label>
                    <input
                      type="checkbox"
                      disabled={occupied}
                      onChange={(e) => {
                        if (e.target.checked) {
                          vm.groupUsernames.push(player.username!);

                        } else {
                          const i = vm.groupUsernames.indexOf(player.username!);
                          if (i >= 0) {
                            vm.groupUsernames.splice(i, 1);
                          }
                        }
                        console.log(vm.groupUsernames);
                      }} />
                    {player.displayName}
                  </label>
                </div>)
              })}
            </div>
            <br />
            <br />
            <input type='submit' value="OK"
              onClick={async () => {
                vm.busy = true;
                try {
                  await api.createPlayerGroup(vm.groupTitle, vm.groupUsernames, vm.userAuth!.username, vm.userAuth!.token);
                  vm.groupTitle = "";
                  vm.groupUsernames = [];
                  history.goBack();
                } finally {
                  vm.busy = false;
                }
              }}
            />
            <input type='button' value='CANCEL'
              onClick={async () => {
                history.goBack();
              }}
            />

          </fieldset>
        </form>
      </Route>
      <Route path="/ace-race/score-card/:hole">
        {/* {() => {
          console.log("location", location);
          console.log("hole", hole);
          console.log("history", history);
          console.log("routeMatch", routeMatch);
          return 4;
        }} */}

        <HolePager scoreCard={vm.scoreCard} allPlayers={vm.allPlayers} />
      </Route>
      <Route path="/ace-race/score-card">

        <h1>Score Card</h1>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <fieldset disabled={vm.busy}>
            <ul>
              {vm.scoreCard?.playerScores.map((item, index) => {
                return <li key={index}>
                  {
                    vm.allPlayers.find((player) => {
                      return player.username === item.username;
                    })?.displayName
                  }
                </li>;
              })}
            </ul>

            <button onClick={async () => {
              history.push("/ace-race/score-card/1")

            }}>KEEP SCORE</button>
            <button onClick={() => { history.goBack() }}>BACK</button>
            <br />
          </fieldset>
        </form>
      </Route>
      <Route path="/ace-race/live-scores">
        <h1>Live Scores</h1>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <fieldset disabled={vm.busy}>

            <button onClick={() => { history.goBack() }}>BACK</button>
            <br />
          </fieldset>
        </form>
      </Route>
      <Route path="*">
        {/* <NotFound /> */}
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <fieldset disabled={vm.busy}>
            <span>{vm.userAuth.username}</span>
            <button onClick={async () => { await vm.logout(); }}>LOGOUT</button>
            <br />
            {(vm.userAuth.type === 'admin') &&
              <Fragment>
                <button onClick={async () => { history.push("/ace-race/create-event") }}>CREATE EVENT</button>

                <br />
                <button onClick={async () => { history.push("/ace-race/create-player-login") }}>CREATE PLAYER LOGIN</button>
                <br />
                <button onClick={async () => {
                  try {
                    vm.busy = true;
                    vm.allPlayers = await api.listPlayers(vm.userAuth!.username, vm.userAuth!.token)
                    vm.allPlayers.sort((a, b) => {
                      if (a.displayName! > b.displayName!) return 1;
                      if (a.displayName! < b.displayName!) return -1;
                      return 0;
                    })

                    const playerGroups = await api.listPlayerGroups(vm.userAuth!.username, vm.userAuth!.token);
                    vm.occupiedPlayers = [];
                    playerGroups.forEach((playerGroup) => { vm.occupiedPlayers.push(...playerGroup.groupUsernames!) })

                    vm.groupTitle = "";
                    vm.groupUsernames = [];
                    history.push("/ace-race/create-player-group");
                  } finally {
                    vm.busy = false;
                  }
                }}>
                  CREATE PLAYER GROUP
                </button>
                <br />
              </Fragment>}

            {(vm.userAuth.type === 'player') &&
              <Fragment>
                <button onClick={async () => {
                  vm.busy = true;
                  try {
                    const scoreCard = await api.getScoreCard(vm.userAuth!.username, vm.userAuth!.token);
                    console.log("scoreCard", scoreCard);
                    const allPlayers = await api.listPlayers(vm.userAuth!.username, vm.userAuth!.token);
                    console.log("allPlayers", allPlayers);

                    vm.scoreCard = scoreCard;
                    vm.allPlayers = allPlayers;
                    history.push("/ace-race/score-card");

                  } catch {
                    alert("Score Card not available. Talk to the Tournament Director.");
                  } finally {
                    vm.busy = false;
                  }
                }}>OPEN SCORE CARD</button>
                <br />
                <button onClick={async () => {
                  history.push("/ace-race/live-scores");
                }}>VIEW LIVE SCORES</button>
                <br />
              </Fragment>
            }
          </fieldset >
        </form>
      </Route>
    </Switch>
    {/* <button onClick={
        async(e)=>{
       
          const events = await api.listEvents();
          console.log("events",events);

          //const id ="MVP_Sp_Ace_Race_at_Wheeler_Park_2023";
          const id = events[0].id!;
          const event = await api.getEvent(id);
          console.log("event",event);
        }
      }
      >TEST</button> */}

  </div >;
});


