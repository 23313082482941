import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route, Link, useHistory } from "react-router-dom";

interface INotFoundProps {}

export const NotFound = observer((props: INotFoundProps) => {
  const history = useHistory();
  return (
    <Fragment>
      <p>not found</p>
      <p>
        <button
          className="btn btn-primary"
          onClick={() => {
            history.goBack();
          }}
        >
          back
        </button>
        <button
          className="btn btn-primary ms-3"
          onClick={() => {
            history.push("/");
          }}
        >
          home
        </button>
      </p>
    </Fragment>
  );
});
