import { useState } from "react";
import { ThrowScoreValue } from "./ThrowScoreValue";


const defaultHandler = () => { };

interface IThrowScoreInputProps {
    value?: ThrowScoreValue
    enableBirdie?: boolean;
    onValueChanged?: (newValue: ThrowScoreValue) => void;
}

export function ThrowScoreInput(props: IThrowScoreInputProps) {
    const [value, setValue] = useState(props.value);
    const onValueChanged = props.onValueChanged || defaultHandler;
    return (<span>
        <button
            style={(value === 'miss') ? { fontWeight: 'bold' } : {}}
            onClick={() => { setValue('miss'); onValueChanged('miss'); }}
        >MISS</button>
        <button
            style={(value === 'metal') ? { fontWeight: 'bold' } : {}}
            onClick={() => { setValue('metal'); onValueChanged('metal'); }}
        >METAL</button>
        <button
            style={(value === 'chains') ? { fontWeight: 'bold' } : {}}
            onClick={() => { setValue('chains'); onValueChanged('chains'); }}
        >CHAINS</button>
        <button
            style={(value === 'ace') ? { fontWeight: 'bold' } : {}}
            onClick={() => { setValue('ace'); onValueChanged('ace'); }}
        >ACE</button>

        {props.enableBirdie &&
            <button
                style={(value === 'putt') ? { fontWeight: 'bold' } : {}}
                onClick={() => { setValue('putt'); onValueChanged('putt'); }}
            >BIRDIE</button>
        }
    </span>)
}